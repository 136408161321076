<template>
  <div class="section__lk-content container-shadow fill-white">
    <div class="section__lk-title">Библиотека</div>
    <template v-if="books">
      <div class="section__lk-content-items">
        <div v-for="book in books" :key="book.id" class="section__lk-content-item" @click="openAttach(book)">
          <modal-pdf-view v-if="!book.can_download && pdf.link" :id="'show-book-' + book.id" :link="pdf.link" :title="pdf.title" />
          <div class="section__lk-content-item-left">
            <div class="col-auto book-item__ext text-center">
              <img src="/pic/icon-doc.svg" alt="" title=""/>
              <div class="mt-1">pdf</div>
            </div>
            <div class="section__lk-content-item-separator"></div>
            <div class="section__lk-content-item-data">
              <div class="section__lk-content-item-data-content">
                <div>{{ book.title }}</div>
              </div>
              <div v-if="book.date" class="mt-1">
                {{ book.date | formatDate('D') }} {{ book.date | formatDate('M') }} {{ book.date | formatDate('Y') }}
              </div>
              <div class="section__lk-content-item-data-title">
                <template v-if="book.can_download">
                  Скачать <icon-download :fill="'#ED1B2F'" />
                </template>
                <template v-else>
                  Читать <img src="/pic/profile/arrow-up-right-red.svg" />
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section__lk-content-items" v-if="currentPage < books.total/books.limit">
        <div class="load-more__wrapper">
          <button class="btn btn-primary" @click="currentPage++">
            <span></span> Загрузить ещё
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import $ from 'jquery';
import 'bootstrap'
import ApiService from "../../../../services/api.service";
import "vue-pdf-app/dist/icons/main.css";
import ModalPdfView from "@/components/ModalPdfView.vue";
import IconDownload from "@/components/icons/IconDownload.vue";
export default {
  name: "Libs",
  components: {
    IconDownload,
    ModalPdfView
  },
  data() {
    return {
      books: null,
      pdf: {
        title: null,
        link: null
      },
      currentPage: 1
    }
  },
   mounted() {
    this.load()
  },
  methods: {
    async load() {
      let books = (await ApiService.get('companydocument', {
        params: {
          limit: this.currentPage * 50
        }
      })).data

      if(books) {
        this.books = books.docs
      }
    },
    async openAttach(document) {
      this.pdf.title = document.title
      if(document.link) {
        this.pdf.link = document.link
      }
      if(document.file) {
        this.pdf.link = document.file.file
      }

      this.pdf.can_download = document.can_download

      if(this.pdf.link) {
        !this.pdf.can_download ? await $('#show-book-' + document.id).modal('show') : window.open(this.pdf.link, '_blank')
      }
    }
  },
  watch: {
    currentPage() {
      this.load()
    }
  }
}
</script>

<style scoped>
 .load-more__wrapper {
   margin-bottom: 4rem;
 }
</style>